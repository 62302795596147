.home_nav ul {
    white-space: normal;
    padding: 0;
}

.home_nav ul li {
    display: inline-block;
    padding: 5px 20px 15px;
}
.home_nav ul li a:visited,
.home_nav ul li a {
    color: blue;
    text-decoration: none;
}
.home_nav ul li a:hover {
    color: darkblue;
    text-decoration: underline;
}
